<template>
  <transition name="fade">
    <div class="tcourse-term-row">
      <div class="date">
        <div class="date-box onsite">
          <small>
            <strong>{{ $t('training') }}</strong>
            {{ $t('at_workspace') }}
          </small>
        </div>
      </div>
      <div class="place">
        <div class="dates__label">
          {{ $t('place') }}
        </div>
        <span>
          {{ $t('individual') }}
        </span>
      </div>
      <div class="duration">
        <div class="dates__label">
          {{ $t('duration') }}
        </div>
        {{ duration }}
      </div>
      <div class="capacity" />
      <div class="lang">
        <div class="dates__label">
          {{ $t('language') }}
        </div>
        {{ $t(`language_translation.${language}`) }}
      </div>
      <div class="original-price">
      </div>
      <div class="price price--onsite">
        <div class="dates__label">
          {{ $t('price') }}
        </div>
        <strong>
          {{ $t('individual') }}
        </strong>
      </div>
      <div class="order">
        <a class="btn-red" data-remote="true" :href="orderLink">{{ $t('i_am_interested') }}</a>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Onsite',
  props: ['duration', 'id', 'language', 'place', 'price', 'price_eur', 'price_eur_old', 'price_old'],
  computed: {
    orderPath() {
      return this.$i18n.locale === 'cs' ? '' : 'en/';
    },
    orderLink() {
      return `/${this.orderPath}event_requests/new?event_id=${this.id}&type=OnsiteRequest`;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<i18n>
{
  "cs": {
    "at_workspace": "na Vašem pracovišti",
    "individual": "Individuální",
    "training": "Školení na míru",
    "course": "Kurz",
    "capacity": "Kapacita",
    "online": "e-Learning",
    "or": "nebo",
    "duration": "Délka",
    "days_duration": ["den", "dny", "dní"],
    "i_am_interested": "Mám zájem",
    "language": "Jazyk",
    "at_workspace": "Proškolení v místě Vašeho pracoviště",
    "language_translation": {
      "cs": "Česky",
      "en": "Anglicky",
      "sk": "Slovensky"
    },
    "place": "Místo",
    "price": "Cena",
    "repayments": "Chci kurz na splátky",
    "term": "Termín"
  },
  "sk": {
    "at_workspace": "Proškolení v místě Vašeho pracoviště",
    "course": "Kurz",
    "online": "Online",
    "capacity": "Kapacita",
    "or": "alebo",
    "duration": "Dĺžka",
    "days_duration": ["deň", "dni", "dní"],
    "i_am_interested": "Mám záujem",
    "language": "Jazyk",
    "language_translation": {
      "cs": "Česky",
      "en": "Anglicky",
      "sk": "Slovensky"
    },
    "place": "Miesto",
    "price": "Cena",
    "repayments": "Chcem kurz na splátky",
    "term": "Termín"
  },
  "en": {
    "training": "Training",
    "at_workspace": "at your workplace",
    "course": "Course",
    "online": "Online",
    "capacity": "Capacity",
    "or": "or",
    "duration": "Duration",
    "days_duration": ["day", "days", "days"],
    "i_am_interested": "Book",
    "language": "Language",
    "language_translation": {
      "cs": "Czech",
      "en": "English",
      "sk": "Slovak"
    },
    "place": "Location",
    "price": "Price",
    "repayments": "Pay later",
    "term": "Date"
  }
}
</i18n>
