import axios from 'axios';
import Qs from 'qs';

const instance = axios.create({
  baseURL: '/api/v1/',
});

instance.interceptors.request.use((config) => {
  /* eslint no-param-reassign: "error" */

  config.paramsSerializer = params => Qs.stringify(params, {
    arrayFormat: 'brackets',
    encode: false,
  });
  return config;
});

export const updateInstance = (updater) => { updater(instance); };

export default instance;
