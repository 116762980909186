<template>
  <transition name="fade">
    <div class="tcourse-term-row">
      <div class="date date--preorder">
        <div class="date-box preorder">
          <strong>{{ $t('preparing') }}</strong>
          {{ $t('tema') }}
        </div>
      </div>
      <div class="place">
        <div class="dates__label">
          {{ $t('place') }}
        </div>
        <span>
          <a
            :href="linkToInfoPage"
            target="_blank"
            class="virtual-link"
            v-if="showVirtualClass"
          >{{ $t('virtual') }}</a>
          <span class="virtual-divider" v-if="showVirtualClass">{{ $t('or') }}</span> <span>{{ place }}</span>
        </span>
      </div>
      <div class="duration">
        <div class="dates__label">
          {{ $t('duration') }}
        </div>
        {{ duration }}
      </div>
      <div class="lang">
        <div class="dates__label">
          {{ $t('language') }}
        </div>
        {{ $t(`language_translation.${language}`) }}
      </div>
      <div class="order">
        <a class="btn-red" data-remote="true" :href="orderLink">{{ $t('i_am_interested') }}</a>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Preorder',
  props: ['duration', 'id', 'language', 'place', 'url'],
  data() {
    return {
      linkToInfoPage: `${this.i18n.locale}/online-kurzy-virtual-training-vs-elearning`,
    };
  },
  computed: {
    showVirtualClass() {
      return this.$root.$data.virtualClassEnabled;
    },
    orderPath() {
      return this.$i18n.locale === 'cs' ? '' : 'en/';
    },
    orderLink() {
      return `/${this.orderPath}event_requests/new?event_id=${this.id}&type=PreorderRequest`;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<i18n>
{
  "cs": {
    "preparing": "Připravujeme první kurz",
    "tema": "na toto téma v České republice",
    "or": "nebo",
    "duration": "Délka",
    "days_duration": ["den", "dny", "dní"],
    "i_am_interested": "Mám zájem",
    "language": "Jazyk",
    "language_translation": {
      "cs": "Česky",
      "en": "Anglicky",
      "sk": "Slovensky"
    },
    "place": "Místo",
    "virtual": "Virtuálne",
    "term": "Termín"
  },
  "sk": {
    "preparing": "Připravujeme první kurz",
    "tema": "na toto téma v České republice",
    "or": "alebo",
    "duration": "Dĺžka",
    "days_duration": ["deň", "dni", "dní"],
    "i_am_interested": "Mám záujem",
    "language": "Jazyk",
    "virtual": "Virtuálně",
    "language_translation": {
      "cs": "Česky",
      "en": "Anglicky",
      "sk": "Slovensky"
    },
    "place": "Miesto",
    "term": "Termín"
  },
  "en": {
    "preparing": "We are preparing the first course",
    "tema": "on this topic in the Czech Republic",
    "or": "or",
    "duration": "Duration",
    "days_duration": ["day", "days", "days"],
    "i_am_interested": "Book",
    "language": "Language",
    "virtual": "Virtual",
    "language_translation": {
      "cs": "Czech",
      "en": "English",
      "sk": "Slovak"
    },
    "place": "Place",
    "term": "Term"
  }
}
</i18n>
