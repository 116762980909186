import { mapGetters } from 'vuex';

const priceMixin = {
  computed: {
    ...mapGetters(['activeCurrency', 'primaryCurrency']),
    priceOldInCurrency() {
      return this.activeCurrency === this.primaryCurrency ? this.price_old : this.price_eur_old;
    },
    priceInCurrency() {
      return this.activeCurrency === this.primaryCurrency ? this.price : this.price_eur;
    },
  },
};

export default priceMixin;
