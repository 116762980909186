import 'es6-promise/auto';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import Vuex from 'vuex';
import BootstrapVue from 'bootstrap-vue';

import { currency } from '../course_events/filters/currency';
import {
  date, dayName, day, monthAndYear,
} from '../course_events/filters/dates';

import i18n from '../lib/i18n';

import App from '../course_events/App.vue';
import store from '../course_events/store';

Vue.use(BootstrapVue);
Vue.use(Vuex);

Vue.filter('currency', currency);
Vue.filter('date', date);
Vue.filter('dayName', dayName);
Vue.filter('monthAndYear', monthAndYear);
Vue.filter('day', day);

Vue.filter('capitalize', (value) => {
  if (!value) return '';
  const newValue = value.toString();
  return newValue.charAt(0).toUpperCase() + newValue.slice(1);
});

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('events');

  const virtualClassEnabled = element.dataset.virtual === 'true';
  const onlyEUR = element.dataset.onlyEur === 'true';
  /* eslint no-new: 0 */

  if (element) {
    new Vue({
      data: {
        virtualClassEnabled,
        onlyEUR,
      },
      el: '#events',
      i18n,
      store,
      components: { App },
      render: h => h(App),
      mounted() {
        if (onlyEUR === true) {
          this.$store.dispatch('setPrimaryCurrency', 'eur');
          this.$store.dispatch('setCurrency', 'eur');
        }
      },
    });
  }
});
