import { includes } from 'ramda';
import i18n from '../../lib/i18n';

const czkCurrency = (value) => {
  if (value === 0) {
    return i18n.t('free');
  }
  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency: 'CZK',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const eurCurrency = (value) => {
  if (value === 0) {
    return i18n.t('free');
  }
  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

export function currency(value, localeOrCurrency = i18n.locale) {
  if (includes(localeOrCurrency, ['sk', 'eur'])) {
    return eurCurrency(value);
  }

  if (includes(localeOrCurrency, ['cs', 'czk'])) {
    return czkCurrency(value);
  }

  return czkCurrency(value);
}
