/* eslint no-param-reassign: ["error", { "props": false }] */

import Vue from 'vue';
import Vuex from 'vuex';
import {
  uniq, filter, not, compose, isNil, isEmpty, all, equals, sortBy,
} from 'ramda';
import { getField, updateField } from 'vuex-map-fields';

import moment from 'moment';
import api from '../../common/config/apiAxiosInstance';
import i18n from '../../lib/i18n';

moment.locale(i18n.locale);

Vue.use(Vuex);

const notNull = compose(
  not,
  isNil,
);

const getFormattedDate = (event) => {
  const date = moment(event.date_from);
  return date.isValid() ? date.format('MMMM YYYY') : null;
};

const EVENT_PER_PAGE = 5;

// initial state
const state = {
  events: [],
  loadingError: false,
  loadingErrorMessage: '',
  beforeInitialFetch: true,
  date: '',
  language: '',
  place: '',
  currency: i18n.locale === 'cs' ? 'czk' : 'eur',
  primaryCurrency: 'czk',
  order: 'date',
  currentPage: 1,
};

// getters
const getterMethods = {
  getField,
  activeOrder: state => state.order,
  activeCurrency: state => state.currency,
  primaryCurrency: state => state.primaryCurrency,
  availableMoreEvents: (state, getters) => state.events.length > getters.showEventCount,
  allEvents: state => state.events,
  dateOptions: (state) => {
    const collection = uniq(state.events.map(event => getFormattedDate(event)));
    return filter(notNull, collection);
  },
  placeOptions: state => uniq(state.events.map(event => event.place)),
  cheepestEvent: state => state.events.reduce((prev, curr) => {
    if (prev.price == null) {
      return curr;
    }
    return prev.price < curr.price ? prev : curr;
  }, []),
  languageOptions: state => uniq(state.events.map(event => event.language)),
  filterAttributes: state => ({
    date: state.date,
    language: state.language,
    place: state.place,
  }),
  filteredEvents: (state, getters, rootState) => {
    const { date, language, place } = getters.filterAttributes;

    const result = state.events.filter((event) => {
      const matchByDate = !isEmpty(date) ? getFormattedDate(event) === date : true;
      const matchByLanguage = !isEmpty(language) ? event.language === language : true;
      const matchByPlace = !isEmpty(place) ? event.place === place : true;

      return all(equals(true))([matchByDate, matchByLanguage, matchByPlace]);
    });

    const sortByColumn = sortBy(event => event[getters.sortColumn]);

    return sortByColumn(result.slice(0, getters.showEventCount));
  },
  showEventCount: state => state.currentPage * EVENT_PER_PAGE,
  sortColumn: state => (state.order === 'date' ? 'date_from' : 'price'),
};

// actions
const actions = {
  loadEvents({ commit, getters }) {
    api.defaults.baseURL = `${window.location.origin}${window.location.pathname}/`;

    api
      .get('events.json')
      .then((response) => {
        commit('setEventsData', response.data.events);
        commit('setBeforeInitialFetch', false);
      })
      .catch((error) => {
        commit('setError', 'Something went wrong');
      });
  },
  setCurrency({ commit }, payload) {
    commit('setCurrency', payload);
  },
  setPrimaryCurrency({ commit }, payload) {
    commit('setPrimaryCurrency', payload);
  },
  setOrder({ commit }, payload) {
    commit('setOrder', payload);
  },
  showMoreEvents({ commit }, payload) {
    commit('showMoreEvents');
  },
};

// mutations
const mutations = {
  updateField,
  updateFilterField(state, field) {
    updateField(state.filterState, field);
    this.dispatch('loadFirstPageEvents');
  },
  setBeforeInitialFetch(state, value) {
    state.beforeInitialFetch = value;
  },
  setEventsData(state, payload) {
    state.events = payload;
  },
  setOrder(state, payload) {
    state.order = payload;
  },
  setCurrency(state, payload) {
    state.currency = payload;
  },
  setPrimaryCurrency(state, payload) {
    state.primaryCurrency = payload;
  },
  showMoreEvents(state) {
    state.currentPage += 1;
  },
  setError(state, errorMessage) {
    state.loadingError = true;
    state.loadingErrorMessage = errorMessage;
  },
};

const store = new Vuex.Store({
  state,
  getters: getterMethods,
  actions,
  mutations,
});

export default store;
