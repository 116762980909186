import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const lang = document.getElementsByTagName('html')[0].getAttribute('lang') || 'cs';

const i18n = new VueI18n({
  locale: lang,
  messages: {
    cs: {
      free: 'zdarma',
    },
    en: {
      free: 'free',
    },
    sk: {
      free: 'zadarmo',
    },
  },
});

export default i18n;
