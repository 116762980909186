<template>
  <div>
    <Online v-bind="event" v-if="isOnline" />
    <Onsite v-bind="event" v-if="isOnsite" />
    <Preorder v-bind="event" v-if="isPreOrder" />
    <Regular v-bind="event" v-if="isRegular" />
  </div>
</template>

<script>
import Online from './Online';
import Onsite from './Onsite';
import Preorder from './Preorder';
import Regular from './Regular';

export default {
  name: 'CourseEvent',
  components: {
    Online,
    Onsite,
    Preorder,
    Regular,
  },
  props: ['event'],
  computed: {
    isRegular() {
      return !this.isOnline && !this.isOnsite && !this.isPreOrder;
    },
    isOnline() {
      return this.event.online === true;
    },
    isPreOrder() {
      return this.event.preorder === true;
    },
    isOnsite() {
      return this.event.onsite === true;
    },
  },
};
</script>
