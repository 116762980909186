<template>
  <transition name="fade">
    <div class="tcourse-term-row">
      <div class="date">
        <div class="date-box">
          <template>
            <span> {{ date_from | day }}</span>
            <small>
              <strong>
                {{ date_from | monthAndYear | capitalize }}
              </strong>
              <small class="dates__day">{{ date_from | dayName | capitalize }}</small>
            </small>
          </template>
        </div>
      </div>
      <div class="place">
        <div class="dates__label">
          {{ $t('place') }}
        </div>
        <div class="dates__text">
          <span v-if="event_type === 'both'">
            <a
              href="/online-kurzy-virtual-training-vs-elearning"
              target="_blank"
              class="virtual-link"
            >{{ $t('virtual') }}</a>
            <span class="virtual-divider">{{ $t('or') }}</span> <span>{{ place }}</span>
          </span>
          <span v-else-if="event_type === 'virtual'">
            <a
              href="/online-kurzy-virtual-training-vs-elearning"
              target="_blank"
              class="virtual-link"
            >
              {{ $t('virtual') }}
            </a>        
          </span>
          <span v-else>{{ place }}</span>
        </div>
      </div>
      <div class="duration">
        <div class="dates__label">
          {{ $t('duration') }}
        </div>
        {{ duration }}
      </div>
      <div class="capacity">
        <div class="dates__label">
          {{ $t('capacity') }}
        </div>
        <small>{{ capacity }}</small>
      </div>
      <div class="lang">
        <div class="dates__label">
          {{ $t('language') }}
        </div>
        {{ $t(`language_translation.${language}`) }}
      </div>
      <div class="original-price">
        <div class="dates__label">
          {{ $t('original_price') }}
        </div>
        <strike>{{ priceOldInCurrency | currency(activeCurrency) }}</strike>
      </div>
      <div class="price">
        <div class="dates__label">
          {{ $t('price_without_vat') }}
        </div>
        <div class="dates__text dates__text--price">
          {{ priceInCurrency | currency(activeCurrency) }}
        </div>
      </div>
      <div class="order">
        <a class="btn-red" :href="orderLink">{{ $t('i_am_interested') }}</a>
        <br>
        <div class="installments">
          <a :href="repaymentsLink">{{ $t('repayments') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import priceMixin from '../lib/priceMixin';

export default {
  name: 'Regular',
  mixins: [priceMixin],
  props: [
    'capacity',
    'date_from',
    'duration',
    'event_type',
    'id',
    'language',
    'place',
    'price',
    'price_eur',
    'price_eur_old',
    'price_old',
  ],
  computed: {
    orderPath() {
      return this.$i18n.locale !== 'en' ? 'objednavky' : 'en/orders';
    },
    showVirtualClass() {
      return this.$root.$data.virtualClassEnabled;
    },
    isVirtual() {
      return this.event_type == 'virtual';
    },
    orderLink() {
      const virtual = this.isVirtual ? '&virtual=true' : '';
      return `/${this.orderPath}/set_event_session?event_id=${this.id}${virtual}`;
    },    
    repaymentsLink() {
      return `/${this.orderPath}/set_event_session?event_id=${this.id}&repayments=true`;
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<i18n>
{
  "cs": {
    "course": "Kurz",
    "capacity": "Kapacita",
    "original_price": "Cena před slevou",
    "or": "nebo",
    "duration": "Délka",
    "days_duration": ["den", "dny", "dní"], 
    "i_am_interested": "Mám zájem",
    "language": "Jazyk",
    "language_translation": {
      "cs": "Česky",
      "en": "Anglicky",
      "sk": "Slovensky"
    },
    "place": "Místo",
    "virtual": "Virtuálně",
    "price_without_vat": "Cena bez DPH",
    "repayments": "Chci kurz na splátky",
    "term": "Termín"
  },
  "sk": {
    "course": "Kurz",
    "original_price": "Cena před slevou",
    "capacity": "Kapacita",
    "or": "alebo",
    "duration": "Dĺžka",
    "days_duration": ["deň", "dni", "dní"],
    "i_am_interested": "Mám záujem",
    "language": "Jazyk",
    "virtual": "Virtuálne",
    "language_translation": {
      "cs": "Česky",
      "en": "Anglicky",
      "sk": "Slovensky"
    },
    "place": "Miesto",
    "price_without_vat": "Cena bez DPH",
    "repayments": "Chcem kurz na splátky",
    "term": "Termín"
  },
  "en": {
    "course": "Course",
    "original_price": "Original price",
    "capacity": "Capacity",
    "or": "or",
    "duration": "Duration",
    "days_duration": ["day", "days", "days"],    
    "i_am_interested": "Book",
    "language": "Language",
    "virtual": "Virtual",
    "language_translation": {
      "cs": "Czech",
      "en": "English",
      "sk": "Slovak"
    },
    "place": "Location",
    "price_without_vat": "Price without VAT",
    "repayments": "Pay later",
    "term": "Date"
  }
}
</i18n>
