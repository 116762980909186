<template>
  <div class="dates">
    <div v-if="!emptyResults">
      <CourseEvent v-for="event in filteredEvents" :key="event.id" :event="event" />
    </div>
    <div v-else-if="beforeInitialFetch">
      {{ $t('loading') }}
    </div>
    <div v-else>
      {{ $t('no_events_found') }}
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CourseEvent from './CourseEvent';

export default {
  name: 'CourseEventsList',
  computed: {
    ...mapGetters(['filteredEvents']),
    ...mapState(['beforeInitialFetch']),
    emptyResults() {
      return this.filteredEvents.length === 0;
    },
  },
  components: {
    CourseEvent,
  },
};
</script>

<i18n>
{
  "cs": {
    "loading": "Načítám…",
    "no_events_found": "Nebyly nalezeny žádné odpovídající termíny!"
  },
  "sk": {
    "loading": "Načítam…",
    "no_events_found": "Neboli nájdené žiadne odpovedajúce termíny!"
  },
  "en": {
    "loading": "Loading…",
    "no_events_found": "No matching terms found!"
  }
}
</i18n>
