<template>
  <div class="courses form-filter mt-5">
    <div class="course-sort">
      <div class="earliest">
        <a
          :class="{ 'btn-red': activeOrder === 'date', 'btn-gray': activeOrder !== 'date' }"
          @click.prevent="setOrder('date')"
        >
          <strong>{{ $t('closest_courses') }}</strong>
        </a>
      </div>
      <div class="best-price">
        <a
          :class="{ 'btn-red': activeOrder === 'price', 'btn-gray': activeOrder !== 'price' }"
          @click.prevent="setOrder('price')"
        >
          <strong>
            <span class="desc">{{ $t('best_course_price') }}</span>
            <span class="price"> {{ $t('from') }} {{ bestPrice }}</span>
          </strong>
        </a>
      </div>
    </div>
    <div class="tcourse-filter">
      <div class="wrapper">
        <div class="caption">
          <p>
            {{ $t('filtering') }} <strong>{{ $t('course_offers') }}</strong>
          </p>
        </div>
        <div class="choice">
          <b-form-select v-model="place" :options="placeOptions" class="form-control">
            <template slot="first">
              <option value="">
                {{ $t('select_place') }}
              </option>
            </template>
            <slot />
          </b-form-select>
        </div>
        <div class="choice">
          <b-form-select v-model="date" :options="translatedDateOptions" class="form-control" ref="course">
            <template slot="first">
              <option value="">
                {{ $t('select_month') }}
              </option>
            </template>
            <slot />
          </b-form-select>
        </div>
        <div class="choice">
          <b-form-select v-model="language" :options="translatedLanguageOptions" class="form-control" ref="course">
            <template slot="first">
              <option value="">
                {{ $t('select_language') }}
              </option>
            </template>
            <slot />
          </b-form-select>
        </div>
        <div class="currency" v-if="showCurrencySwitcher">
          <p>
            <span>{{ $t('currency') }}</span>
            <a :class="{ active: activeCurrency == 'czk' }" @click.prevent="setCurrency('czk')" href="#">
              {{ $t('czk') }}
            </a>
            <span class="sep">|</span>
            <a :class="{ active: activeCurrency == 'eur' }" @click.prevent="setCurrency('eur')" href="#">EUR</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'CertificationsFilter',
  data() {
    return {
      bestPriceEvent: {
        price: 0,
        price_eur: 0,
      },
    };
  },
  computed: {
    ...mapFields(['date', 'place', 'language']),
    ...mapGetters([
      'placeOptions',
      'dateOptions',
      'languageOptions',
      'cheepestEvent',
      'activeOrder',
      'activeCurrency',
      'primaryCurrency',
    ]),
    translatedLanguageOptions() {
      return this.languageOptions.map(language => ({
        value: language,
        text: this.$t(`language_translation.${language}`),
      }));
    },
    translatedDateOptions() {
      return this.dateOptions.map(date => ({
        value: date,
        text: this.$options.filters.capitalize(date),
      }));
    },
    bestPrice() {
      const price = this.activeCurrency === this.primaryCurrency
        ? this.bestPriceEvent.price : this.bestPriceEvent.price_eur;
      return price ? this.$root.$options.filters.currency(price, this.activeCurrency) : 'N/A';
    },
    showCurrencySwitcher() {
      return !this.$root.$data.onlyEUR;
    },
  },
  methods: {
    setOrder(order) {
      this.$store.dispatch('setOrder', order);
    },
    setCurrency(currency) {
      this.$store.dispatch('setCurrency', currency);
    },
  },
  watch: {
    cheepestEvent(newValue, oldValue) {
      this.bestPriceEvent = newValue;
    },
  },
};
</script>

<i18n>
{
  "cs": {
    "czk": "Kč",
    "best_course_price": "Nejlepší ceny školení",
    "closest_courses": "Nejbližší volné termíny",
    "course_offers": "nabídky kurzů",
    "currency": "Měna",
    "filtering": "Filtrování",
    "from": "od",
    "select_language": "Vyberte jazyk",
    "select_month": "Vyberte měsíc",
    "select_place": "Vyberte místo",
    "language_translation": {
      "cs": "Česky",
      "en": "Anglicky",
      "sk": "Slovensky"
    }
  },
  "sk": {
    "czk": "Kč",
    "closest_courses": "Last minute kurzy",
    "best_course_price": "Najlepšie ceny školení",
    "course_offers": "ponuka kurzov",
    "currency": "Měna",
    "filtering": "Filtrovanie",
    "from": "od",
    "select_language": "Vyberte jazyk",
    "select_month": "Vyberte mesiac",
    "select_place": "Vyberte mesto",
    "language_translation": {
      "cs": "Česky",
      "en": "Anglicky",
      "sk": "Slovensky"
    }
  },
  "en": {
    "czk": "CZK",
    "closest_courses": "Last minute courses",
    "best_course_price": "The best training course prices",
    "course_offers": "course offers",
    "currency": "Currency",
    "filtering": "Filtering",
    "from": "from",
    "select_language": "Select language",
    "select_month": "Select month",
    "select_place": "Select place",
    "language_translation": {
      "cs": "Czech",
      "en": "English",
      "sk": "Slovak"
    }
  }
}
</i18n>
