<template>
  <div>
    <CourseEventsFilter />
    <CourseEventsList />
    <div class="text-center">
      <a v-if="availableMoreEvents" class="btn-red" href="#" @click.prevent="loadMoreEvents">
        {{ $t('show_more_terms') }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CourseEventsList from './components/CourseEventsList.vue';
import CourseEventsFilter from './components/CourseEventsFilter.vue';

export default {
  name: 'App',
  components: {
    CourseEventsList,
    CourseEventsFilter,
  },
  created() {
    this.fetchEvents();
  },
  computed: {
    ...mapGetters(['availableMoreEvents']),
  },
  methods: {
    fetchEvents() {
      this.$store.dispatch('loadEvents');
    },
    loadMoreEvents() {
      this.$store.dispatch('showMoreEvents');
    },
  },
};
</script>

<i18n>
{
  "cs": {
    "show_more_terms": "Zobrazit více termínů"
  },
  "sk": {
    "show_more_terms": "Zobraziť viac termínov"
  },
  "en": {
    "show_more_terms": "Show more terms"
  }
}
</i18n>
