import moment from 'moment';
import i18n from '../../lib/i18n';

moment.locale(i18n.locale);

export function date(value) {
  return moment(value).format('D. MMMM');
}

export function dayName(value) {
  return moment(value).format('dddd');
}

export function monthAndYear(value) {
  return moment(value).format('MMMM YYYY');
}

export function day(value) {
  return moment(value).format('D');
}
